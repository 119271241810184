import React, { useEffect, useState } from 'react'

import styles from './Statistic.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
} from 'chart.js'

import { Stat } from './Stat/RegionCard'
import { statisticActions } from 'store/slices/Statistic.slice'
import { useTranslation } from 'react-i18next'
import { dates } from 'features/Delegate/Data'
import classNames from 'classnames'
import { appActions } from 'store/slices/App.slice'

ChartJS.register(
	RadialLinearScale,
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
)

export const Statistic = () => {
	const year = useSelector((state) => state.app.year)
	const { selectedRegion, regions, questionStatuses } = useSelector(
		(state) => state.stats,
	)
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const onYearClickHanlder = (year) => {
		if (new Date().getFullYear() < year.value) {
			dispatch(
				appActions.setInfoMessage(
					year.value + ' ' + t('delegats.willYearText'),
				),
			)
		} else {
			dispatch(appActions.setYear(year.value))
		}
	}

	useEffect(() => {
		dispatch(statisticActions.setSelectedRegion(regions[0]))
	}, [regions])

	if (!regions.length || !questionStatuses.length) return

	return (
		<div className={styles.stats}>
			<div className={styles.date}>
				<span className={styles.dateText}>{t('delegats.year.text')}: </span>
				{dates.map((item) => (
					<button
						key={item.id}
						type='button'
						className={classNames(
							styles.dateBtn,
							year === item.value && styles.dateBtnActive,
						)}
						onClick={() => onYearClickHanlder(item)}
					>
						{item.value}
					</button>
				))}
			</div>
			{selectedRegion ? <Stat /> : null}
		</div>
	)
}
