import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
//
import styles from './Delegate.module.scss'
import { SliderNextButton, SliderPrevButton, Title } from 'components'
import { DelegateItem } from './Item/DelegateItem'
import { DelegateClickableItem } from './Item/DelegateClickableItem'
import { SwiperSlide, Swiper } from 'swiper/react'
import { Grid, Autoplay, Pagination } from 'swiper/modules'
import { useTranslation } from 'react-i18next'

//
import 'swiper/css'
import 'swiper/css/grid'

//
import { dates } from './Data'

import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { UseData } from 'hooks/UseData'
import { appActions } from 'store/slices/App.slice'
//
import SearchIcon from 'assets/images/search.svg'

//
import CloseIcon from 'assets/images/close.svg'

export const Delegate = () => {
	const sliderRef = useRef(null)
	const { citizens } = useSelector((state) => state.delegation)
	const { t } = useTranslation()
	const [slideCount, setSlideCount] = useState({
		count: 4,
		rows: 2,
		per: 4,
	})

	const resize = (e) => {
		if (e.target.innerWidth < 700) {
			setSlideCount({ count: 2, rows: 2, per: 2 })
		} else {
			setSlideCount({ count: 4, rows: 2, per: 4 })
		}
	}

	useEffect(() => {
		window.addEventListener('resize', resize)
		resize({ target: { innerWidth: window.innerWidth } })
		return () => {
			window.addEventListener('resize', resize)
		}
	}, [])

	return (
		<>
			<div className={styles.delegate} id='delegates'>
				<Title text={t('home.title.delegate')} className={styles.title} />
				{citizens.length > 0 && (
					<div className={styles.content}>
						<div className={styles.sliderArrow}>
							<div className={styles.btnWrapper}>
								<SliderPrevButton sliderRef={sliderRef} />
							</div>
							<Swiper
								slidesPerView={slideCount.count}
								grid={{
									rows: slideCount.rows,
									fill: 'row',
								}}
								ref={sliderRef}
								slidesPerGroup={slideCount.per}
								pagination={{ clickable: true }}
								autoplay={{ delay: 5000 }}
								spaceBetween={50}
								modules={[Grid, Pagination, Autoplay]}
								className={styles.slider}
							>
								{citizens.length &&
									citizens.map((delegat) => (
										<SwiperSlide key={delegat.id}>
											<DelegateItem
												delegat={delegat}
												className={styles.delegat}
											/>
										</SwiperSlide>
									))}
							</Swiper>
							<div className={styles.btnWrapper}>
								<SliderNextButton sliderRef={sliderRef} />
							</div>
						</div>
					</div>
				)}
				{citizens.length === 0 && (
					<>
						<h1 style={{ textAlign: 'center' }}>
							Тандалган жыл боюнча делегаттар каттала элек
						</h1>
						<br />
						<br />
					</>
				)}
			</div>
		</>
	)
}

export const DelegateClickable = () => {
	const year = useSelector((state) => state.app.year)
	const { citizensResult, selected } = useSelector((state) => state.delegation)
	const { getDelegations, getMoreItems } = UseData()
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const sliderRef = useRef(null)
	const [slideCount, setSlideCount] = useState({
		count: 3,
		rows: 2,
		per: 2,
		isAdaptive: false,
	})
	const [searchText, setSearchText] = useState('')
	const [page, setPage] = useState(1)

	const resize = (e) => {
		if (e.target.innerWidth < 900) {
			setSlideCount({
				rows: 2,
				count: 1,
				per: 1,
				isAdaptive: true,
			})
		} else {
			setSlideCount({
				count: 3,
				rows: 2,
				per: 2,
				isAdaptive: false,
			})
		}
	}

	useEffect(() => {
		window.addEventListener('resize', resize)
		resize({ target: { innerWidth: window.innerWidth } })
		return () => {
			window.addEventListener('resize', resize)
		}
	}, [])

	const getData = () => {
		if (selected.ak.id) {
			return getDelegations({ locationId: selected.ak.id, year })
		}
		if (selected.district.id) {
			return getDelegations({ locationId: selected.district.id, year })
		}
		if (selected.region.id) {
			return getDelegations({ locationId: selected.region.id, year })
		}

		getDelegations({ locationId: null, year })
	}

	const loadMoreItems = async () => {
		if (citizensResult.length > 6) {
			getMoreItems({
				pageNumber: page + 1,
				pageSize: 50,
				locationId:
					selected.ak.id || selected.district.id || selected.region.id || null,
				year,
			})
			setPage((prev) => prev + 1)
		}
	}

	const onYearClickHanlder = (year) => {
		if (new Date().getFullYear() < year.value) {
			dispatch(
				appActions.setInfoMessage(
					year.value + ' ' + t('delegats.willYearText'),
				),
			)
		} else {
			dispatch(appActions.setYear(year.value))
		}
	}

	const searchByName = async () => {
		getMoreItems(
			{
				pageNumber: 1,
				pageSize: 700,
				locationId: null,
				fullName: searchText,
				year,
			},
			true,
		)
	}

	useEffect(() => {
		if (!searchText) {
			getData()
			return () => {}
		}

		const timeout = setTimeout(() => {
			searchByName()
		}, 1500)

		return () => clearTimeout(timeout)
	}, [searchText])

	useEffect(() => {
		getData()
	}, [])

	return (
		<>
			<div className={styles.pickDate}>
				<div className={styles.date}>
					<span className={styles.dateText}>{t('delegats.year.text')}: </span>
					{dates.map((item) => (
						<button
							key={item.id}
							type='button'
							className={classNames(
								styles.dateBtn,
								year === item.value && styles.dateBtnActive,
							)}
							onClick={() => onYearClickHanlder(item)}
						>
							{item.value}
						</button>
					))}
				</div>
				<div className={styles.searchBlock}>
					<img
						src={searchText ? CloseIcon : SearchIcon}
						alt='search'
						className={styles.searchIcon}
						onClick={() => setSearchText('')}
					/>
					<input
						type='text'
						placeholder={t('delegats.search')}
						className={styles.search}
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
					></input>
				</div>
			</div>
			{citizensResult.length ? (
				<div className={styles.sliderNav}>
					{citizensResult.length > 6 && (
						<SliderPrevButton sliderRef={sliderRef} />
					)}
					<Swiper
						slidesPerView={slideCount.count}
						grid={{
							rows:
								citizensResult.length === 3 ||
								citizensResult.length === 4 ||
								(citizensResult.length === 5 && !slideCount.isAdaptive)
									? 1
									: slideCount.rows,
							fill: 'row',
						}}
						slidesPerGroup={slideCount.per}
						loop={false}
						autoplay={{ delay: 7000, disableOnInteraction: false }}
						onReachEnd={loadMoreItems}
						spaceBetween={25}
						modules={[Grid, Autoplay]}
						className={styles.slider}
						ref={sliderRef}
					>
						{citizensResult.map((item, key) => (
							<SwiperSlide key={key}>
								<motion.div
									transition={{
										duration: 1,
										type: 'spring',
									}}
									key={item.pin}
									initial={{ y: 200 }}
									animate={{ y: 0 }}
								>
									<DelegateClickableItem
										className={styles.delegateItem}
										delegateItem={item}
										num={key + 1}
										bgClassName={styles.bgClassName}
									/>
								</motion.div>
							</SwiperSlide>
						))}
					</Swiper>
					{citizensResult.length > 6 && (
						<SliderNextButton sliderRef={sliderRef} />
					)}
				</div>
			) : (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ ease: 'linear', duration: 1 }}
					style={{ textAlign: 'center', margin: '100px 0 ' }}
				>
					{t('delegats.notfound')}
				</motion.div>
			)}
		</>
	)
}
