import Slide1 from 'assets/images/mock/slide1.png'
import Slide2 from 'assets/images/mock/slide2.png'
import Slide3 from 'assets/images/mock/slide3.png'
import Slide4 from 'assets/images/mock/slide4.png'

import Slide5 from 'assets/images/mock/slide5.png'
import Slide6 from 'assets/images/mock/slide6.png'
import Slide7 from 'assets/images/mock/slide7.png'
import Slide8 from 'assets/images/mock/slide8.png'
import Slide9 from 'assets/images/mock/slide9.png'

export const slider_mocks = [
	Slide3,
	Slide2,
	Slide8,
	Slide1,
	Slide4,
	Slide5,
	Slide9,
	Slide7,
	Slide6,
]
