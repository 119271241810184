import React from "react";
import { motion, AnimatePresence } from "framer-motion";

import styles from "./Queue.module.scss";

export const QueueAdaptive = ({ total }) => {
  return (
    <div className={styles.willSpeakers}>
      <p className={styles.title}>Кезекте</p>
      <div className={styles.scroll}>
        <AnimatePresence mode="popLayout">
          {total.map((speaker, key) => (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", duration: 2.5 }}
              className={styles.smallDelegat}
              key={speaker.Citizen.Pin}
              id={speaker.Id}
              exit={{ scale: 0, y: -500 }}
            >
              <div className={styles.key}>{speaker?.OrderNumber}</div>
              <div>
                <img src={speaker?.Citizen.PhotoPath} />
                <div>
                  <p>
                    {speaker?.Citizen.LastName} {speaker?.Citizen.FirstName}{" "}
                    {speaker?.Citizen.PaternalName}
                  </p>
                  <p>{speaker?.Citizen.Location.Value}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};
