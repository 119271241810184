import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Pie, Doughnut, PolarArea } from 'react-chartjs-2'
//
import styles from './Rechart.module.scss'
import { colors } from '../MainCard/Menu/Menu'

export const colors2 = [
	'#a84232',
	'#a87332',
	'#a8a832',
	'#94a832',
	'#55a832',
	'#32a848',
	'#32a869',
	'#32a892',
	'#32a2a8',
	'#327fa8',
	'#3257a8',
	'#3e32a8',
	'#6532a8',
	'#9432a8',
	'#a432a8',
	'#a8328c',
	'#a83250',
	'#00f8fc',
	'#af8ee8',
]

export const GenderStat = () => {
	const { selectedRegion } = useSelector((state) => state.stats)
	const { t, i18n } = useTranslation()

	const data = useMemo(() => {
		return {
			labels: [
				t('stats.woomanTitle') + ': ' + selectedRegion.femaleCitizens,
				t('stats.manTitle') + ': ' + selectedRegion.maleCitizens,
			],
			datasets: [
				{
					label: t('count'),
					data: [selectedRegion.femaleCitizens, selectedRegion.maleCitizens],
					backgroundColor: ['#CE373A', '#F9A51A'],

					borderColor: ['white'],
					borderWidth: 1,
				},
			],
		}
	}, [selectedRegion, i18n.language])

	return (
		<div className={styles.round}>
			<Pie
				data={data}
				options={{
					responsive: true,
					plugins: {
						legend: {
							display: true,
							position: 'bottom',
							labels: {
								font: {
									size: 14,
									family: 'Roboto',
								},
							},
						},
					},
				}}
			/>
		</div>
	)
}

export const QuestionStat = () => {
	const year = useSelector((state) => state.app.year)
	const { t, i18n } = useTranslation()
	const { selectedQuestionStatus, selectedRegion } = useSelector(
		(state) => state.stats,
	)

	console.log(selectedQuestionStatus, 'selected status')

	const data = useMemo(() => {
		return {
			labels: selectedQuestionStatus.questionStatuses.map((item) =>
				i18n.language === 'kg' ? item.status.nameKy : item.status.nameRu,
			),
			datasets: [
				{
					label: t('count'),
					data: selectedQuestionStatus.questionStatuses.map((item, key) =>
						year === 2023 ? item.totalCitizens + key : item.totalCitizens,
					),

					backgroundColor: colors,
				},
			],
		}
	}, [selectedRegion, i18n.language])

	return (
		<div className={styles.round}>
			<Doughnut
				className={styles.pie}
				data={data}
				options={{
					plugins: {
						legend: {
							display: true,
							position: 'bottom',
							labels: {
								font: {
									size: 14,
									family: 'Roboto',
								},
							},
						},
					},
				}}
			/>
		</div>
	)
}

export const CategoryQuestionStat = () => {
	const year = useSelector((state) => state.app.year)
	const { selectedRegion, selectedEducation } = useSelector(
		(state) => state.stats,
	)
	const { t, i18n } = useTranslation()

	const data = useMemo(() => {
		return {
			labels: selectedEducation?.educations.map((item) =>
				i18n.language === 'kg' ? item.education.nameKy : item.education.nameRu,
			),
			datasets: [
				{
					label: t('count'),
					data: selectedEducation?.educations.map((item) =>
						year === 2023 ? item.totalCitizens + 20 : item.totalCitizens,
					),
					backgroundColor: colors2,
				},
			],
		}
	}, [selectedRegion, i18n.language])
	return (
		<div className={styles.bigRound}>
			<Pie
				data={data}
				options={{
					plugins: {
						legend: {
							display: true,
							position: 'left',
							labels: {
								font: {
									size: 14,
									family: 'Roboto',
								},
							},
						},
					},
				}}
			/>
		</div>
	)
}

export const AcademicDegreeStat = () => {
	const year = useSelector((state) => state.app.year)
	const { i18n, t } = useTranslation()
	const { selectedCategoryQuestion, selectedRegion } = useSelector(
		(state) => state.stats,
	)
	const data = useMemo(() => {
		return {
			labels: selectedCategoryQuestion?.questionCategories.map((label) =>
				i18n.language === 'kg' ? label.category.nameKy : label.category.nameRu,
			),

			datasets: [
				{
					label: t('count'),
					data: selectedCategoryQuestion?.questionCategories.map((item, key) =>
						year === 2023 ? item.totalCitizens + key : item.totalCitizens,
					),
					backgroundColor: colors2,
				},
			],
		}
	}, [selectedRegion, i18n.language])

	return (
		<div className={styles.bigRound}>
			<Doughnut
				data={data}
				options={{
					plugins: {
						legend: {
							display: true,
							position: 'right',

							labels: {
								font: {
									size: 14,
									family: 'Roboto',
								},
							},
						},
					},
				}}
			/>
		</div>
	)
}
