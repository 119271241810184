import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	errorMessage: '',
	infoMessage: '',
	successMessage: '',
	language: {
		id: 'kg',
		value: 'кырг',
	},
	year: new Date().getFullYear(),
}

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setErrorMessage: (state, action) => {
			state.errorMessage = action.payload
		},
		setInfoMessage: (state, action) => {
			state.infoMessage = action.payload
		},
		setSuccessMessage: (state, action) => {
			state.successMessage = action.payload
		},
		setLanguage: (state, action) => {
			state.language = action.payload
		},
		setYear: (state, action) => {
			state.year = action.payload
		},
	},
})

export const appReducer = appSlice.reducer
export const appActions = appSlice.actions
