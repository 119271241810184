import { useEffect } from 'react'
//
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'

import styles from './MotinoringPage.module.scss'
import { useTranslation } from 'react-i18next'
import { statisticActions } from 'store/slices/Statistic.slice'
import { GetCategoryQuestionCategoryStatistic } from 'api/routes/Statistic'
import DropIcon from 'assets/images/dropdown.svg'
import UseVisible from 'hooks/UseVisible'
import CloseIcon from 'assets/images/close.svg'

export const HorizontalMenu = () => {
	const year = useSelector((state) => state.app.year)
	const { regions, selectedRegion } = useSelector((state) => state.stats)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(statisticActions.setSelectedRegion(regions[0]))
	}, [])

	const selectRegionHandler = async (regionId) => {
		try {
			dispatch(statisticActions.setSelectedChild(null))
			const region = regions.find((reg) => reg.regionId === regionId)

			if (region) {
				dispatch(statisticActions.setSelectedRegion(region))

				const { data } = await GetCategoryQuestionCategoryStatistic(
					`?year=${year}&locationId=` + regionId,
				)
				dispatch(statisticActions.setCategoryQuestionStatistic(data))
			}
		} catch (e) {
			console.log(e)
		}
	}

	const setDefaultSelectedRegion = () => {
		selectRegionHandler(regions[0].regionId)
	}

	const selectChildHandler = async (childId) => {
		try {
			const { data } = await GetCategoryQuestionCategoryStatistic(
				`?year=${year}&locationId=` + childId,
			)

			dispatch(statisticActions.setSelectedRegion(null))

			dispatch(statisticActions.setCategoryQuestionStatistic(data))
		} catch (e) {
			console.log(e)
		}
	}

	if (!regions.length && selectedRegion) return

	return (
		<motion.div
			className={styles.menu}
			initial={{ x: -100 }}
			animate={{ x: 0 }}
			transition={{ duration: 1, type: 'spring' }}
		>
			{regions.map((region) => (
				<Region
					region={region}
					key={region.regionId}
					selectRegionHandler={selectRegionHandler}
					selectChildHandler={selectChildHandler}
					setDefaultSelectedRegion={setDefaultSelectedRegion}
				/>
			))}
		</motion.div>
	)
}

const Region = ({
	region,
	selectRegionHandler,
	selectChildHandler,
	setDefaultSelectedRegion,
}) => {
	const { t } = useTranslation()
	const { selectedRegion } = useSelector((state) => state.stats)
	const { selectedChild } = useSelector((state) => state.stats)
	const { isComponentVisible, setIsComponentVisible, ref } = UseVisible()
	const dispatch = useDispatch()

	const setSelectedChild = (item) => {
		dispatch(statisticActions.setSelectedChild(item))
	}

	const replaceName = (item) => {
		if (item.regionId === 8000) {
			return t('etnos')
		}
		if (item.regionId === 8001) {
			return t('migrant')
		}

		return item.regionName
	}

	return (
		<div className={styles.menuItem}>
			<button
				key={region.regionId}
				onClick={() =>
					region.regionChilds?.length
						? setIsComponentVisible(true)
						: selectRegionHandler(region.regionId)
				}
				style={{
					borderBottom:
						region.regionId === selectedRegion?.regionId
							? '2px solid #2C53B0'
							: 'none',
					color:
						region.regionId === selectedChild?.parentId ? '#2C53B0' : 'black',
				}}
			>
				{region.regionId === 4948
					? t('stats.kyrgyzstan')
					: region.regionId === selectedChild?.parentId
					? selectedChild.name
					: replaceName(region)}
				{region.regionChilds?.length ? (
					region.regionId === selectedChild?.parentId ? (
						<img
							src={CloseIcon}
							alt='drop'
							onClick={() => {
								setSelectedChild(null)
								setDefaultSelectedRegion()
							}}
						/>
					) : (
						<img src={DropIcon} alt='drop' />
					)
				) : null}
			</button>
			<div ref={ref}>
				{isComponentVisible && (
					<motion.div
						className={styles.dropMenu}
						initial={{ scale: 0, translate: '-50%' }}
						animate={{ scale: 1, translate: '-50%' }}
						transition={{ duration: 0.5, type: 'spring' }}
					>
						<button
							className={styles.listItem}
							type='button'
							onClick={() => {
								setIsComponentVisible(false)
								selectRegionHandler(region.regionId)
							}}
						>
							{region.regionName}
						</button>
						<hr></hr>
						{region.regionChilds?.map((item) => (
							<button
								type='button'
								key={item.id}
								className={styles.listItem}
								onClick={() => {
									setIsComponentVisible(false)
									selectChildHandler(item.id)
									setSelectedChild(item)
								}}
							>
								{item.name}
							</button>
						))}
					</motion.div>
				)}
			</div>
		</div>
	)
}
