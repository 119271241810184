import { Route, Routes } from 'react-router-dom'
//
import { HomePage, DelegationsPage, ExamplePage, DelegatePage } from 'pages'
import { StatisticPage } from 'pages/StatisticPage/StatisticPage'
import { NotFound } from 'pages/NotFound/NotFound'
import { QueueAdmin } from 'pages/QueuePage/QueueAdmin'
import { MonitoringPage } from 'pages/MonitoringPage/MonitoringPage'
import { useEffect } from 'react'
import { UseData } from 'hooks/UseData'
import { Splash } from 'components/index'
import { QueuePageVersion2 } from 'pages/QueuePage/Version2/QueuePageVersion2'
import { useSelector } from 'react-redux'

const excludePaths = ['queue', 'queue-admin']

export const UseRoutes = () => {
	const year = useSelector((state) => state.app.year)
	const { getInitialData, loading } = UseData()

	useEffect(() => {
		const locationPath = window.location.href.split('/')

		if (!excludePaths.includes(locationPath[locationPath.length - 1])) {
			getInitialData(year)
		}
	}, [year])

	if (loading) return <Splash />

	return (
		<Routes>
			<Route path='/' element={<HomePage />} />
			<Route path='/delegations' element={<DelegationsPage />} />
			<Route path='/delegate/:id' element={<DelegatePage />} />
			<Route path='/stats' element={<StatisticPage />} />
			<Route path='example' element={<ExamplePage />} />
			<Route path='queue' element={<QueuePageVersion2 />} />
			<Route path='queue-admin' element={<QueueAdmin />} />
			<Route path='monitoring' element={<MonitoringPage />} />
			<Route path='*' element={<NotFound />} />
		</Routes>
	)
}
