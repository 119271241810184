import React, { useEffect, useRef } from 'react'

//
import styles from './Map.module.scss'
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import { MapData, MapKgData, StatsMapData } from './Data/MapData'
import { MapKgText, MapRuText } from './Data/MapText'
import { delegationActions } from 'store/slices/Delegation.slice'
import { UseData } from 'hooks/UseData'
import classNames from 'classnames'
import { UseSelect } from 'features/Statistic/Stat/MainCard/Menu/UseSelect'
//

export const MapKg = () => {
	const year = useSelector((state) => state.app.year)
	const ref = useRef(null)
	const { selected, filters } = useSelector((state) => state.delegation)
	const dispatch = useDispatch()
	const { getDistrictData, getDelegations } = UseData()
	useEffect(() => {
		for (let children of ref.current.children) {
			if (selected.region.id === parseInt(children.id)) {
				children.classList.add(styles.selected)
			} else {
				children.classList.remove(styles.selected)
			}
		}
	}, [selected])

	const onSelect = (itm, city) => {
		const item = filters.region.find((item) => item.id === itm.id)

		if (!item) return

		dispatch(
			delegationActions.setDelegationSelected({
				ak: {},
				district: {},
				region: item,
			}),
		)

		getDistrictData(item.id)
		getDelegations({ locationId: item.id, year })
	}

	const resetFilters = () => {
		dispatch(
			delegationActions.setDelegationSelected({
				region: {},
				district: {},
				ak: {},
			}),
		)
		dispatch(
			delegationActions.setDelegationFilters({
				...filters,
				district: {},
				ak: {},
			}),
		)
		getDelegations({ locationId: null, year })
	}

	return (
		<motion.div
			className={styles.map}
			initial={{ scale: 0 }}
			animate={{ scale: 1 }}
			transition={{ duration: 1, ease: 'linear' }}
		>
			<div>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className={styles.mapRow}
					fill='none'
					viewBox='0 0 1621 810'
					ref={ref}
				>
					<MapKgData onClick={onSelect} />
					<MapKgText />
				</svg>
			</div>
		</motion.div>
	)
}

export const MapRu = () => {
	const year = useSelector((state) => state.app.year)
	const ref = useRef(null)
	const { selected, filters } = useSelector((state) => state.delegation)
	const dispatch = useDispatch()
	const { getDistrictData, getDelegations } = UseData()

	useEffect(() => {
		for (let children of ref.current.children[0].children) {
			if (selected.region.id === parseInt(children.id)) {
				children.classList.add(styles.selected)
			} else {
				children.classList.remove(styles.selected)
			}
		}
	}, [selected])

	const onSelect = (itm) => {
		const item = filters.region.find((item) => item.id === itm.id)

		if (!item) return

		dispatch(
			delegationActions.setDelegationSelected({
				ak: {},
				district: {},
				region: item,
			}),
		)

		getDistrictData(item.id)
		getDelegations({ locationId: item.id, year })
	}

	const resetFilters = () => {
		dispatch(
			delegationActions.setDelegationSelected({
				region: {},
				district: {},
				ak: {},
			}),
		)
		dispatch(
			delegationActions.setDelegationFilters({
				...filters,
				district: {},
				ak: {},
			}),
		)
		getDelegations({ locationId: null, year })
	}

	return (
		<motion.div
			className={styles.map}
			initial={{ scale: 0 }}
			animate={{ scale: 1 }}
			transition={{ duration: 1, ease: 'linear' }}
		>
			<div>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className={styles.mapRow}
					fill='none'
					viewBox='0 0 1024 505'
					ref={ref}
				>
					<g clipPath='url(#a)'>
						<MapData onClick={onSelect} />
						<MapRuText />
					</g>
					<defs>
						<clipPath id='a'>
							<path fill='#fff' d='M0 0h1024v505H0z' />
						</clipPath>
					</defs>
				</svg>
			</div>
		</motion.div>
	)
}

export const StatsMainMap = ({ className, mapRowClassName }) => {
	const { regions } = useSelector((state) => state.stats)

	const { selectPage } = UseSelect()
	const ref = useRef(null)

	const onSelect = (item, color) => {
		const region = regions.find((region) => region.regionId === item.id)

		if (!region) return

		selectPage({ ...region, color })
	}

	return (
		<motion.div
			className={classNames(className, styles.map)}
			initial={{ scale: 0 }}
			animate={{ scale: 1 }}
			transition={{ duration: 1, ease: 'linear' }}
		>
			<div>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className={mapRowClassName}
					fill='none'
					viewBox='0 0 1024 505'
					ref={ref}
				>
					<g clipPath='url(#a)'>
						<StatsMapData onClick={onSelect} />
					</g>
					<defs>
						<clipPath id='a'>
							<path fill='#fff' d='M0 0h1024v505H0z' />
						</clipPath>
					</defs>
				</svg>
			</div>
		</motion.div>
	)
}
