import { motion } from 'framer-motion'
//
import { useTranslation } from 'react-i18next'

export const OrderItem = ({ order, className }) => {
	const { t } = useTranslation()
	return (
		<a href={order.doc} target='_blank' rel='noreferrer'>
			<motion.div
				className={className}
				whileHover={{
					scale: 1.1,
					rotate: 1,
				}}
				transition={{
					duration: 1,
					type: 'spring',
				}}
				initial={{ scale: 0 }}
				animate={{ scale: 1 }}
			>
				<img src={order.icon} alt='order' />
				<p style={order.id === 3 ? { fontSize: 18 } : {}}>{order.text}</p>
				<div
					style={{
						marginLeft: 15,
						borderRadius: '12px',
						background: '#fbad32',
						padding: '10px 20px',
						width: 100,
						position: 'absolute',
						bottom: 15,
						textAlign: 'center',
					}}
				>
					<a
						href='#'
						style={{
							color: 'white',
							fontWeight: 'bold',
						}}
					>
						{t('home.statsCard.more')}
					</a>
				</div>
			</motion.div>
		</a>
	)
}
