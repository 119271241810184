import React, { useEffect, useState } from "react";
import cn from "classnames";
//
import styles from "./Notify.module.scss";
import { useDispatch, useSelector } from "react-redux";
//
import CloseIcon from "assets/images/close.svg";
import { appActions } from "store/slices/App.slice";

export const Notify = () => {
  const [view, setView] = useState(false);
  const [color, setColor] = useState("");
  const dispatch = useDispatch();

  const { errorMessage, successMessage, infoMessage } = useSelector(
    (state) => state.app
  );

  useEffect(() => {
    if (!errorMessage && !successMessage && !infoMessage) return;

    if (errorMessage) setColor("#ce373a");
    if (successMessage) setColor("green");
    if (infoMessage) setColor("black");

    setView(true);

    const timeout = setTimeout(() => setView(false), 5000);

    return () => clearTimeout(timeout);
  }, [errorMessage, successMessage, infoMessage]);

  const onClear = () => {
    dispatch(appActions.setErrorMessage(""));
    dispatch(appActions.setInfoMessage(""));
    dispatch(appActions.setSuccessMessage(""));
    setView(false);
  };

  return (
    <div className={cn(styles.notify, view && styles.view)}>
      <span style={{ color }}>
        {errorMessage || successMessage || infoMessage}
      </span>
      <span>
        <button type="button" className={styles.close} onClick={onClear}>
          <img src={CloseIcon} alt="close" />
        </button>
      </span>
    </div>
  );
};
