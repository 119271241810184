import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

//
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
//
import styles from "./SliderButton.module.scss";
//
import PrevBtn from "assets/images/slider/prev.png";
import NextBtn from "assets/images/slider/next.png";

export const SliderPrevButton = ({ sliderRef }) => {
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  return (
    <motion.button
      tpye="button"
      onClick={handlePrev}
      className={styles.btn}
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
    >
      <img src={PrevBtn} alt="prev" width="50" height="50" />
    </motion.button>
  );
};

export const SliderNextButton = ({ sliderRef }) => {
  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <motion.button
      tpye="button"
      onClick={handleNext}
      className={styles.btn}
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
    >
      <img src={NextBtn} alt="next" width="50" height="50" />
    </motion.button>
  );
};

export const BackButton = () => {
  const navigate = useNavigate();

  const handle = useCallback(() => {
    navigate(-1);
  }, []);
  return (
    <motion.button
      tpye="button"
      onClick={handle}
      className={styles.btn}
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
    >
      <img src={PrevBtn} alt="next" width="50" height="50" />
    </motion.button>
  );
};
