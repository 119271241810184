import React from "react";
//
import styles from "./Footer.module.scss";
//
import ImageLogo from "assets/images/footer/footer-bg.svg";
import TundukLogo from "assets/images/logo_tunduk.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  const data = [
    {
      title: "Разделы сайта",
      data: [
        {
          title: "Делегаты",
          href: "/delegations",
        },
        {
          title: "Показатели в цифрах",
          href: "/stats",
        },

        // {
        //   title: "Мониторинг выполнения",
        //   href: "/monitoring",
        // },
      ],
    },
    {
      title: "Информационные ресурсы",
      data: [
        {
          title: "Указ Президента Кыргызской Республики",
          href: "#orders",
        },
        {
          title:
            "Конституционный Закон Кыргызской Республики «О Народном Курултае»",
          href: "#orders",
        },
        {
          title: "Временное положение Кыргызской Республики",
          href: "#orders",
        },
      ],
    },
    {
      title: "Информационные ресурсы",
      data: [
        {
          title: "Указ Президента Кыргызской Республики",
          href: "https://president.kg/",
        },
        {
          title:
            "Конституционный Закон Кыргызской Республики «О Народном Курултае»",
          href: "https://www.gov.kg/",
        },
        {
          title: "Временное положение Кыргызской Республики",
          href: "https://digital.gov.kg/",
        },
      ],
    },
  ];

  const data2 = [
    t("footer.contact.text1"),
    t("footer.contact.text2"),
    t("footer.contact.text3"),
  ];
  return (
    <motion.div
      initial={{ y: 500 }}
      animate={{ y: 0 }}
      transition={{ ease: "linear", duration: 1 }}
      className={styles.footer}
    >
      <div className={styles.flex}>
        {data.map((item, key) => (
          <div key={key}>
            <h5>{t(`footer.${key + 1}.title`)}</h5>
            <ul className={styles.list}>
              {item.data.map((itm, key2) => (
                <li key={key2}>
                  {itm.href.includes("#") ? (
                    <a href={itm.href}>{t(`footer.${key + 1}.data.${key2}`)}</a>
                  ) : itm.href.includes("https") ? (
                    <a href={itm.href} target="_blank">
                      {t(`footer.${key + 1}.data.${key2}`)}
                    </a>
                  ) : (
                    <Link to={itm.href}>
                      {t(`footer.${key + 1}.data.${key2}`)}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}

        <div className={styles.contact}>
          <h4>{t("footer.contact.title")}</h4>
          {data2.map((item, key) => (
            <p key={key}>{item}</p>
          ))}
        </div>
      </div>
      <div className={styles.tunduk}>
        <div className={styles.logo}>
          <img src={ImageLogo} alt="logo" />
        </div>
        <img src={TundukLogo} alt="Tunduk" /> <span>© 2024 - TUNDUK</span>
      </div>
    </motion.div>
  );
};
